export const localeToPrivacyNotice = {
  'en-US': 'https://www.riotgames.com/en/privacy-notice',
  'en-GB': 'https://www.riotgames.com/en/privacy-notice',
  'en-AU': 'https://www.riotgames.com/en/privacy-notice',
  'cs-CZ': 'https://www.riotgames.com/en/privacy-notice-CZ',
  'de-DE': 'https://www.riotgames.com/de/privacy-notice-DE',
  'el-GR': 'https://www.riotgames.com/en/privacy-notice-EL',
  'es-ES': 'https://www.riotgames.com/es/privacy-notice-ES',
  'es-MX': 'https://www.riotgames.com/es/privacy-notice-ES',
  'fr-FR': 'https://www.riotgames.com/fr/privacy-notice-FR',
  'hu-HU': 'https://www.riotgames.com/en/privacy-notice-HU',
  'it-IT': 'https://www.riotgames.com/en/privacy-notice-IT',
  'pl-PL': 'https://www.riotgames.com/en/privacy-notice-PL',
  'pt-BR': 'https://www.riotgames.com/pt-br/privacy-notice-BR',
  'ro-RO': 'https://www.riotgames.com/en/privacy-notice-RO',
  'ru-RU': 'https://www.riotgames.com/ru/privacy-notice-RU',
  'tr-TR': 'https://www.riotgames.com/tr/privacy-notice-TR',
  'ja-JP': 'https://www.riotgames.com/ja/privacy-notice-JP',
  'ko-KR': 'https://legal.kr.riotgames.com/privacy',
  'zh-TW': 'https://www.riotgames.com/en/privacy-notice',
  'th-TH': 'https://www.riotgames.com/en/privacy-notice',
  'en-PH': 'https://www.riotgames.com/en/privacy-notice',
  'en-SG': 'https://www.riotgames.com/en/privacy-notice',
  'id-ID': 'https://www.riotgames.com/id/privacy-notice',
  'vi-VN': 'https://www.riotgames.com/vi/privacy-notice'
}
